<template>
  <section>
    <va-breadcrumbs>
      <va-breadcrumbs-item
        label="Educação"
        to="/admin/educacao/coordenadores-all"
      />
      <va-breadcrumbs-item>
        <span style="font-style: italic; color: #0785d3">Diretores</span>
      </va-breadcrumbs-item>
    </va-breadcrumbs>
    <h2 class="titulo-links-escolha">Diretores</h2>
    <p>Listagem de todos os Diretores</p>

    <div class="row"  v-if="situacaoAno">
      <div class="col-md-10"></div>
      <div class="col-md-2">
        <va-button
          class="mr-2 mb-2"
          size="small"
          color="success"
          @click="registar()"
          style="float: right"
        >
          {{ $t("Registrar") }}</va-button
        >
      </div>
    </div>
    <va-input
      v-if="diretorALL.length > 5"
      class="flex mb-2 md6"
      placeholder="Filtrar..."
      v-model="filter"
    />
    <va-data-table
      striped="true"
      hoverable="true"
      animated="true"
      :items="diretorALL"
      :columns="columns"
      :per-page="perPage"
      :filter="filter"
      :current-page="currentPage"
    >
      <template #cell(escolas)="{ source: escolas }">
        <div v-for="n in escolas" :key="n"><b>{{n.nome}} </b></div>
      </template>

      <template #cell(info)="{ source: info }">
        <va-button
          v-if="situacaoAno"
          class="mr-2 mb-2"
          size="small"
          @click="editarCorde(info.id)"
        >
          {{ $t("Editar") }}
        </va-button>

        <va-button
          v-if="situacaoAno"
          class="mr-2 mb-2"
          size="small"
          color="danger"
          @click="deletar(info.id)"
        >
          {{ $t("Deletar") }}
        </va-button>

      </template>

  
      <template #bodyAppend>
        <tr>
          <td colspan="8" class="table-example--pagination">
            <va-pagination v-model="currentPage" input :pages="pages" />
          </td>
        </tr>
      </template>
    </va-data-table>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Professor } from "@/class/professor.js";
import { Calendario } from "@/class/calendario.js";
import { Servidores } from "@/class/servidores.js";
import { DisciplinaEscolar } from "@/class/disciplina.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { Configuracao } from "@/class/configuracao.js";
import { Escola } from "@/class/escolas.js";

import { Diretor } from "@/class/diretor.js";

export default defineComponent({
  data() {
    const columns = [
      { key: "nome", label: "Nome", sortable: true },
      { key: "escola", label: "Escola"  },
      { key: "info", label: "Ação" },
    ];

    return {
      radioSelectedOption:"option1",
      ehFundamentalI:0,
      situacaoAno:1,
      filter: '',
      showMediumModal: false,
      showDisciplinaModal: false,
      showgerenciarHorarioModal:false,
      showgerenciarDisciplinaModal: false,
      tipo:1,
      ano:0,

      tituloModal: "",
      textoModal: "",
      info: [],
      disciplinaALL:[],
      segmentoALL:[],
      escolaALL:[],
      horariosAll:[],

      id_item:0,
      segmentoSelecionado:'',
      disciplinaSelecionada:'',
      professorSelecionado:'',
      escolaSelecionada:1,
      turnoSelecionado:'',
      professor:[],

      diretorALL: [],
      columns,
      perPage: 15,
      currentPage: 1,
    };
  },
  computed: {
    pages() {
      return this.perPage && this.perPage !== "0"
        ? Math.ceil(this.diretorALL.length / this.perPage)
        : this.diretorALL.length;
    },
  },
  methods: {
    registar() {
      this.$router.push({ name: "diretoradd" });
    },
    async deletar(id) {
      if (window.confirm("Você realmente deseja remover está pessoa da direção?")) {
        try{
          let data = await Diretor.remover(id);
          this.$vaToast.init({
              message: "Removido com sucesso!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 2500,
              fullWidth: false,
              color:'success'
          });
          this.listarTudo();
        }catch(e){
            let a = e.response.data.erro;
            this.$vaToast.init({
              message: a,
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 2500,
              fullWidth: false,
              color:'#e42222'
            })
        }
      } 
    },
    editarCorde(id){
      this.$router.push({ name: "diretoreditar", params: { id: id}});
    },
    async listarTudo() {
      try {
        this.diretorALL = [];
        let anoSelect = sessionStorage.getItem("anoSelecionado");
        

        let data = await Diretor.obtemTodos(anoSelect);
        for (const el of data.data) {
          if (!el.situacao) continue;
          let novo = {
            servidor_id: el.servidor_id,
            nome: el.diretor.nome,
            escola: el.escola_nome.nome,
            info: {
              id: el.id,
              id_:el.id,
              nome: el.diretor.nome,
              funcao_id: el.login.funcao,
            },
          };
          this.diretorALL.push(novo);
        }

      } catch (e) {}
    },

  },
  beforeMount() {
    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.listarTudo();
  },
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
